import styles from "./Question.module.scss";

import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class Question extends Component {
  constructor(props) {
    super(props);
    this.ref = createRef();
    this.state = {
      open: false,
      questionHeight: void 0,
    };
  }

  componentDidMount() {
    /*eslint-disable react/no-did-mount-set-state */
    this.setState({ questionHeight: this.ref.current.clientHeight });
  }

  shouldComponentUpdate() {
    return true;
  }

  render() {
    const { children, question, color, borderColor } = this.props;
    const { open, questionHeight } = this.state;
    let qMaxHeight = void 0;
    if (questionHeight) {
      if (open) {
        qMaxHeight = questionHeight;
      } else {
        qMaxHeight = 0;
      }
    }
    return (
      <div
        className={styles["container"]}
        style={borderColor ? { borderColor } : void 0}
      >
        <div
          className={styles["question_container"]}
          onClick={() => {
            this.setState({ open: !open });
          }}
          style={{ color }}
        >
          <div className={styles["question"]}>{question}</div>
          <div>
            <div
              className={classNames(styles["icon"], {
                [styles["reverse"]]: open,
              })}
            >
              &#9698;
            </div>
          </div>
        </div>
        <div
          ref={this.ref}
          className={[styles["answer"]]}
          style={{ maxHeight: qMaxHeight }}
        >
          <div className={styles["text"]} style={{ color }}>
            {children}
          </div>
        </div>
      </div>
    );
  }
}

Question.propTypes = {
  children: PropTypes.node.isRequired,
  question: PropTypes.string.isRequired,
  borderColor: PropTypes.string,
  color: PropTypes.string,
};

Question.defaultProps = {
  borderColor: void 0,
  color: "#0094ff",
};

export default Question;
