import styles from "./FAQPage.module.scss";

import React from "react";
import PropTypes from "prop-types";

import i18n from "./FAQPage.i18n";

import Faq from "../faq/Faq";

import addI18nContextConsumer from "../i18n/addI18nContextConsumer";

function FAQPage({ strings }) {
  return (
    <div className={styles["container"]}>
      <div className={styles["bg_container"]} />
      <div className={styles["content"]}>
        <h1 className={styles["title"]}>FAQ</h1>
        <h3 className={styles["subtitle"]}>{strings["subtitle"]}</h3>
        {strings.faqs.map(({ faq, title }) => (
          <div className={styles["faq_content"]}>
            <Faq color="white" title={title}>
              {faq.map(({ question, answer }) => (
                <Faq.Question
                  borderColor="rgba(255,255,255,0.35)"
                  color="white"
                  question={question}
                >
                  {answer}
                </Faq.Question>
              ))}
            </Faq>
          </div>
        ))}
        <div className={styles["label"]}>{strings["any_question"]}</div>
      </div>
    </div>
  );
}

FAQPage.propTypes = {
  /*eslint-disable react/forbid-prop-types*/
  strings: PropTypes.object.isRequired,
  /*eslint-enable react/forbid-prop-types*/
};

export default addI18nContextConsumer(i18n)(FAQPage);
