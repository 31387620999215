import styles from "./Faq.module.scss";

import React from "react";
import PropTypes from "prop-types";

import Question from "./question/Question";

function FAQ({ children, title, color }) {
  return (
    <div className={styles["container"]}>
      <div className={styles["title"]} style={{ color }}>
        {title}
      </div>
      <div>{children}</div>
    </div>
  );
}

FAQ.Question = Question;

FAQ.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  title: PropTypes.string,
};

FAQ.defaultProps = {
  color: void 0,
  title: "",
};

export default FAQ;
