/*eslint-disable */
import React from "react";

export default {
  en: {
    subtitle: "Frequently Asked Questions",
    faqs: [
      {
        title: "General",
        faq: [
          {
            question:
              "How does BusinessLocator compare to other Store Locators?",
            answer: (
              <>
                {" "}
                <p>
                  It doesn’t! Just kidding, there are great solutions on the
                  market but the way BusinessLocator differentiates itself is by
                  providing a real-time, live preview editor. Because we rely on
                  our own mapping service,{" "}
                  <a href="https://www.jawg.io">Jawg Maps</a>, we can show the
                  map as much as we want without paying a 3rd party — and you
                  don’t need to create a Google Maps account to get an API key!
                  It’s a win-win: we pay less, you pay less and it’s easier for
                  you to create and edit your store locator.
                </p>{" "}
                <p>
                  <strong>Bonus: NO ADS!</strong> Most store locators depend on
                  Google Maps which can display unwanted ads on your map.
                </p>
              </>
            ),
          },
          {
            question:
              "Is it limited to just stores or can it be used for different types of businesses?",
            answer: (
              <p>
                That’s actually why we called it BusinessLocator and not
                StoreLocator. A lot of businesses can benefit from this kind of
                solution to locate their agencies, offices, listings, stores and
                more! If you need to share one or several locations on a map,
                BusinessLocator is the tool for you.
              </p>
            ),
          },
          {
            question:
              "I work for a NGO, NPO, city/administration. Does BusinessLocator make sense for me?",
            answer: (
              <>
                <p>One word: yes!</p>
                <p>
                  Whatever your business, if you welcome visitors they will need
                  to know where you are located. And what is the best way to
                  show them? With a map of course! And because you decide what
                  markers you add to your map, you can show services scattered
                  across different buildings or even show external services
                  around your location that can be useful for your visitors.
                </p>
              </>
            ),
          },
          {
            question: "What information does the store locator show?",
            answer: (
              <p>
                You can display any information you&apos;d like: store name,
                description, phone number, hours of operation and more thanks to
                custom fields. Visitors will also be able to see the distance
                separating them from each location thanks to our distance
                calculator.
              </p>
            ),
          },
        ],
      },
      {
        title: "Security",
        faq: [
          {
            question:
              "How resilient is the store locator service against outages?",
            answer: (
              <p>
                BusinessLocator uses OVH, an enterprise-grade, reliable and
                high-performance provider to provide five-star availability,
                scalability and resilience. Check out our Status page for more
                information on our uptime and performance.
              </p>
            ),
          },
          {
            question: "Is your service GDPR compliant?",
            answer: (
              <p>
                Yes, we don't gather any personally identifiable information
                (PII) from your visitors so you can rest assured! For more
                information, please check our privacy policy.
              </p>
            ),
          },
          {
            question: "Is your service secure?",
            answer: (
              <p>
                Yes, we don&apos;t gather any personally identifiable
                information (PII) from your visitors so you can rest assured!
                For more information, please check our privacy policy.
              </p>
            ),
          },
        ],
      },
      {
        title: "Account",
        faq: [
          {
            question:
              "Do I need to sign up for an API key with Google or Mapbox?",
            answer: (
              <p>
                Nope! That&apos;s one of BusinessLocator&apos;s many benefits.
                We rely on
                <a href="https://www.jawg.io">Jawg Maps</a>, our own mapping
                software, so you don&apos;t need to create an account on a 3rd
                party website to use our services. The API key is built-in and
                already set up for you so you can publish your business locator
                even faster!
              </p>
            ),
          },
          {
            question:
              "What happens if I get more than 30,000 map views per month?",
            answer: (
              <>
                <p>
                  Depending on your plan, you will get charged for each
                  additional 1,000 map views. The rates are:
                </p>
                <ul>
                  <li>Free plan: 4€ per 1,000 map views</li>
                  <li>Premium plan: 3,50€ per 1,000 map views</li>
                  <li>Professional plan: 3,25€ per 1,000 map views</li>
                  <li>Enterprise plan: 3€ per 1,000 map views</li>
                </ul>
                <p>
                  If you are on the Free plan and are about to go over, don’t
                  worry, you will receive plenty of notice to fill out your
                  payment details so that your store locator map keeps loading.
                </p>
              </>
            ),
          },
        ],
      },
      {
        title: "Payment",
        faq: [
          {
            question: "Do I need a card to signup?",
            answer: (
              <p>
                No, you will only need to enter your payment details if you wish
                to continue with a paid plan at the end of your free trial. Get
                started today!
              </p>
            ),
          },
          {
            question: "Can I cancel at anytime?",
            answer: (
              <p>
                Yes, you can cancel at any time by cancelling your plan renewal
                in the Billing section. If you are on a Monthly subscription and
                cancel after payment for the current month, your subscription
                will be active until the end of that billing period. If you are
                on an Annual subscription and wish to cancel before the end of
                the billing period, please contact us.
              </p>
            ),
          },
          {
            question: "Can I switch between plans?",
            answer: (
              <p>
                Yes, you can switch between plans in the Billing section. If you
                downgrade, the premium plan will be active until the end of the
                billing period. If you upgrade, the cost difference will be
                calculated pro-rata depending on what date of the month you make
                the switch.
              </p>
            ),
          },
          {
            question: "Is there a discount for annual payments?",
            answer: (
              <p>
                Yes, we offer Annual subscriptions which give you access to a 10
                to 20% discount depending on the plan! Check out our Pricing
                page for more details.
              </p>
            ),
          },
        ],
      },
      {
        title: "Integration",
        faq: [
          {
            question:
              "Can I set up the store locator on more than one page on my website?",
            answer: (
              <p>
                Yes you can set up the store locator on as many pages as you
                wish. All you have to do is add the embed code on each of them.
              </p>
            ),
          },
          {
            question:
              "Can I link a search bar to my locator from another page?",
            answer: (
              <p>
                Not yet. We hope to implement this feature in the future but for
                the moment, the search bar is linked to the map and cannot be
                shown on another page. This is a deal breaker for you? You can
                check out Jawg Maps Geocoding in the meantime.
              </p>
            ),
          },
        ],
      },
    ],
    any_question: "Any questions? Chat with us today!",
  },
  fr: {
    subtitle: "Foire Aux Questions",
    faqs: [
      {
        title: "Général",
        faq: [
          {
            question:
              "Comment BusinessLocator se compare-t-il aux autres store locators ?",
            answer: (
              <>
                {" "}
                <p>
                  Il ne se compare pas ! On plaisante, il existe de bonnes
                  solutions sur le marché, mais BusinessLocator se différencie
                  car il apporte une expérience unique : vous pouvez éditer en
                  temps réel, directement sur la carte ! Cela est possible car
                  nous utilisons notre propre service de cartographie,{" "}
                  <a href="https://www.jawg.io">Jawg Maps</a>, et vous n'avez
                  donc pas besoin de créer un compte Google Maps pour obtenir
                  une clé API ! C'est tout benef’ : vous payez moins et il vous
                  est plus facile de créer et de modifier votre store locator.
                </p>{" "}
                <p>
                  <strong>Bonus : PAS DE PUBLICITÉ !</strong> La plupart des
                  store locators dépendent de Google Maps qui peut afficher des
                  publicités sur votre carte.
                </p>
              </>
            ),
          },
          {
            question:
              "Est-il limité aux magasins ou peut-il être utilisé pour différents types d'entreprises ?",
            answer: (
              <p>
                C'est pourquoi nous l'avons appelé BusinessLocator et non
                StoreLocator. De nombreuses entreprises peuvent bénéficier de ce
                type de solution pour localiser leurs agences, bureaux,
                produits, magasins et plus encore ! Si vous avez besoin de
                partager un ou plusieurs emplacements sur une carte,
                BusinessLocator est l'outil qu'il vous faut.
              </p>
            ),
          },
          {
            question:
              "Je travaille pour une ONG, OBNL, ville/administration. Est-ce que BusinessLocator a du sens pour moi ?",
            answer: (
              <>
                <p>Un mot : oui !</p>
                <p>
                  Quelle que soit votre activité, si vous accueillez des
                  visiteurs, ils auront besoin de savoir où vous vous situez. Et
                  quelle est la meilleure façon de le montrer ? Avec une carte
                  bien sûr ! C’est vous qui décidez quels marqueurs vous ajoutez
                  à votre carte, donc vous pouvez décider d’afficher des
                  services dispersés dans différents bâtiments ou des services
                  externes autour de votre emplacement qui peuvent être utiles
                  pour vos visiteurs.
                </p>
              </>
            ),
          },
          {
            question:
              "Quelles informations puis-je afficher sur mon store locator ?",
            answer: (
              <p>
                Vous pouvez afficher toutes les informations que vous souhaitez
                : nom du magasin, description, numéro de téléphone, heures
                d'ouverture et plus encore grâce à des champs personnalisés. Les
                visiteurs pourront également voir la distance les séparant de
                chaque emplacement grâce à notre calculateur de distance.
              </p>
            ),
          },
        ],
      },
      {
        title: "Sécurité",
        faq: [
          {
            question:
              "Dans quelle mesure le store locator est-il résistant aux pannes ?",
            answer: (
              <p>
                BusinessLocator utilise OVH, un fournisseur d’hébergement fiable
                et performant pour fournir une disponibilité, une performance et
                une résilience cinq étoiles. Consultez notre page Statut pour
                plus d'informations sur notre disponibilité et nos performances.
              </p>
            ),
          },
          {
            question: "BusinessLocator est-il conforme au RGPD ?",
            answer: (
              <p>
                Oui, nous ne recueillons aucune information personnellement
                identifiable (PII) de vos visiteurs, vous pouvez donc être
                assuré ! Pour plus d'informations, veuillez consulter notre
                politique de confidentialité.
              </p>
            ),
          },
          /*{
            question: "Is your service secure?",
            answer: (
              <p>
                Yes, we don&apos;t gather any personally identifiable
                information (PII) from your visitors so you can rest assured!
                For more information, please check our privacy policy.
              </p>
            ),
          },*/
        ],
      },
      {
        title: "Compte",
        faq: [
          {
            question:
              "Dois-je m'inscrire pour une clé API avec Google ou Mapbox ?",
            answer: (
              <p>
                Non, c'est l&apos;un des nombreux avantages de BusinessLocator.
                Nous nous appuyons sur{" "}
                <a href="https://www.jawg.io">Jawg Maps</a>, notre propre
                logiciel de cartographie, vous n'avez donc pas besoin de créer
                un compte sur un site tiers pour utiliser nos services. La clé
                API est intégrée et déjà configurée pour vous afin que vous
                puissiez publier votre store locator encore plus rapidement !
              </p>
            ),
          },
          {
            question:
              "Que se passe-t-il si j'obtiens plus de 30 000 vues de carte par mois ?",
            answer: (
              <>
                <p>
                  En fonction de votre forfait, vous serez facturé pour chaque 1
                  000 vues de carte supplémentaires. Les tarifs sont :
                </p>
                <ul>
                  <li>Forfait gratuit : 4€ pour 1 000 vues de carte</li>
                  <li>Forfait Premium : 3,50€ pour 1 000 vues de carte</li>
                  <li>
                    Forfait professionnel : 3,25€ pour 1 000 vues de carte
                  </li>
                  <li>Forfait entreprise : 3 € pour 1 000 vues de carte</li>
                </ul>
                <p>
                  Si vous êtes sur le plan gratuit et que vous êtes sur le point
                  de dépasser votre quota, ne vous inquiétez pas, vous recevrez
                  des rappels pour renseigner vos informations de paiement afin
                  que votre store locator continue de s'afficher correctement.
                </p>
              </>
            ),
          },
        ],
      },
      {
        title: "Paiement",
        faq: [
          {
            question: "Ai-je besoin d'une carte pour m'inscrire ?",
            answer: (
              <p>
                Non, vous n'aurez besoin de saisir vos informations de paiement
                que si vous souhaitez continuer avec un forfait payant à la fin
                de votre essai gratuit.
              </p>
            ),
          },
          {
            question: "Puis-je annuler à tout moment ?",
            answer: (
              <p>
                Oui, vous pouvez annuler à tout moment en annulant le
                renouvellement de votre forfait dans la section Facturation. Si
                vous avez souscrit un abonnement mensuel et que vous annulez
                après le paiement du mois en cours, votre abonnement sera actif
                jusqu'à la fin de cette période de facturation. Si vous avez
                souscrit un abonnement annuel et souhaitez annuler avant la fin
                de la période de facturation, veuillez nous contacter.
              </p>
            ),
          },
          {
            question: "Puis-je basculer entre les plans ?",
            answer: (
              <p>
                Oui, vous pouvez basculer entre les plans dans la section
                Facturation. Si vous rétrogradez, le plan le plus élevé sera
                actif jusqu'à la fin de la période de facturation. Si vous
                passez sur un plan supérieur, la différence de coût sera
                calculée au prorata en fonction de la date du mois à laquelle
                vous effectuez le changement.
              </p>
            ),
          },
          {
            question: "Y a-t-il une remise pour les paiements annuels ?",
            answer: (
              <p>
                Oui, nous proposons des abonnements annuels qui vous donnent
                accès à une remise de 10 à 20 % selon les formules ! Consultez
                notre page Tarifs pour plus de détails.
              </p>
            ),
          },
        ],
      },
      {
        title: "Intégration",
        faq: [
          {
            question:
              "Puis-je configurer le store locator sur plusieurs pages de mon site Web ?",
            answer: (
              <p>
                Oui, vous pouvez configurer le localisateur de magasins sur
                autant de pages que vous le souhaitez. Tout ce que vous avez à
                faire est d'ajouter le code d'intégration sur chacune des pages.
              </p>
            ),
          },
          {
            question:
              "Puis-je ajouter une barre de recherche liée à mon store locator sur une autre page ?",
            answer: (
              <p>
                Pas encore. Nous espérons implémenter cette fonctionnalité à
                l'avenir mais pour le moment, la barre de recherche est liée à
                la carte et ne peut pas être affichée sur une autre page. C’est
                indispensable pour vous ? Vous pouvez consulter l’API de
                Geocoding Jawg Maps ou nous contacter pour discuter de votre
                projet sur-mesure.
              </p>
            ),
          },
        ],
      },
    ],
    any_question: "Vous avez des questions ? Contactez-nous via le chat !",
  },
};
